import React from "react"
import Layout from "../../../components/layout"
import { Container } from "../../../components/utils"

const Upcoming = () => {
  return (
    <Layout title="Upcoming Webinars" footerClassName='!mt-5' description="Whether you are a beginner or experienced trader, we have a webinar class tailored for you.">
      <Container size='large' className='pt-[40px]'>
        <iframe width="100%" className='h-[2800px] md:h-[750px]' frameBorder="0"
                src="https://app.livestorm.co/eagle-global-markets/upcoming?limit=10"
                title="Eagle Global Markets events | Livestorm"></iframe>
      </Container>
    </Layout>
  )
}

export default Upcoming